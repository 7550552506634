import { Box, Text, Button } from "@chakra-ui/react";
import 'animate.css';

//image importing

import { default as Staff_1 } from "../assets/staff_avatar/1.svg";

const handleClose = (id) => {
  const modal = document.getElementById(`modal_${id}`);

  modal.classList.remove("animate__fadeIn");
  modal.classList.add("animate__fadeOut");

  setTimeout(() => {
    modal.classList.add("element_disabled");
    modal.classList.remove("animate__fadeOut");

    const overlay = document.getElementsByClassName("overlay_show_staff");
    overlay[0].classList.add("element_disabled");
    
  }, 500);
};


export function ModalStaff() {
  return (
    <Box className="main_modals">
      <Box id="modal_1" className="animate__animated animate__fadeIn containerModal element_disabled">
        <Box className="containerModal_content">
          <Box className="containerModal_content_header" style={{paddingBottom: "10px"}}>
            <h1>Nome Cognome</h1>
            <Box className="container_image" style={{paddingTop: "20px"}}>
              <img src={Staff_1} alt="Staff Member" />
            </Box>
          </Box>
          <p style={{paddingLeft: "10px"}}>
              Laboris minim ut qui tempor cillum voluptate amet occaecat minim
              culpa laboris enim et non. Sit Lorem ut aliqua ad. Lorem cillum
              nostrud incididunt enim qui elit sunt ad ut cupidatat nisi ipsum eu
              cupidatat. Incididunt id id id velit sit ipsum aliqua commodo.
              Cillum fugiat ad ut veniam do officia aute reprehenderit nulla.
            </p>
          
          <Box className="containerModal_content_footer">
            <Button
              className="close_modal"
              colorScheme="teal"
              size="sm"
              onClick={() => handleClose('1')}
            >
              <Text fontSize="md" style={{margin: "0px"}}>Chiudi</Text>
            </Button>

          </Box>
        </Box>
      </Box>
      <Box id="modal_2" className="animate__animated animate__fadeIn containerModal element_disabled">
        <Box className="containerModal_content">
         <Box className="containerModal_content_header" style={{paddingBottom: "10px"}}>
            <h1>Nome Cognome 2</h1>
            <Box className="container_image" style={{paddingTop: "20px"}}>
              <img src={Staff_1} alt="Staff Member" />
            </Box>
          </Box>
          <p style={{paddingLeft: "10px"}}>
            Laboris minim ut qui tempor cillum voluptate amet occaecat minim
            culpa laboris enim et non. Sit Lorem ut aliqua ad. Lorem cillum
            nostrud incididunt enim qui elit sunt ad ut cupidatat nisi ipsum eu
            cupidatat. Incididunt id id id velit sit ipsum aliqua commodo.
            Cillum fugiat ad ut veniam do officia aute reprehenderit nulla.
          </p>
          <Box className="containerModal_content_footer">
            <Button
              className="close_modal"
              colorScheme="teal"
              size="sm"
              onClick={() => handleClose('2')}
            >
              <Text fontSize="md" style={{margin: "0px"}}>Chiudi</Text>
            </Button>

          </Box>
        </Box>
      </Box>
    </Box>
  );
}
