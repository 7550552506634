import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Textarea,
  Tooltip,
  useColorModeValue,
  VStack,
  Text
} from '@chakra-ui/react'

import { useState } from 'react'
import { BsPerson } from 'react-icons/bs'
import { AiOutlineInstagram, AiOutlinePhone } from 'react-icons/ai'
import { MdOutlineEmail } from 'react-icons/md'
import { GoogleMapsIframe } from './GoogleMapsIframe.js'

export function Contact() {
  const [messageSent, setMessageSent] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const handleSendMessage = () => {

    const name = document.querySelector('input[name="name"]').value;
    const email = document.querySelector('input[name="email"]').value;

    const message = document.querySelector('textarea[name="message"]').value;

    const formData = {
      name,
      email,
      message,
    };


    fetch('http://51.77.202.242:3000/invia-email', {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
      redirect: 'follow',
    })
      
      .then((response) => response.text())
      .then((result) => {
        console.log(result);

        setMessageSent(true);
        setIsButtonDisabled(true); //TODO: FIXARE IL BOTTONE CHE NON SI DISABILITA
        setIsLoading(false);
      })
      .catch((error) => { 

        setServerError(true);
        setIsButtonDisabled(true); //TODO: FIXARE IL BOTTONE CHE NON SI DISABILITA
        setIsLoading(false);
      });
  };


  return (
    <Flex
      bg={useColorModeValue('gray.100', 'gray.900')}
      align="center"
      justify="center"
      id="contact">
      <Box borderRadius="lg" m={{ base: 5, md: 16, lg: 10 }} p={{ base: 5, lg: 16 }}>
        <Box>
          <VStack spacing={{ base: 4, md: 8, lg: 20 }}>
            <Heading
              fontSize={{
                base: '4xl',
                md: '5xl',
              }}>
              Vieni a trovarci presso il nostro studio
            </Heading>

            {/* Testo piccolo sotto e vicino ad heading  */}
            <Text
              fontSize={{
                base: 'xl',
                md: '2xl',
              }}
              color={useColorModeValue('gray.600', 'gray.400')}>
              Oppure contattaci per maggiori informazioni o per prenotare una visita
            </Text>


            <Stack
              spacing={{ base: 4, md: 8, lg: 20 }}
              direction={{ base: 'column', md: 'row' }}>
              <Stack
                align="center"
                justify="space-around"
                direction={{ base: 'row', md: 'column' }}>
                <Tooltip
                  label={'Invia una mail'}
                  closeOnClick={false}
                  hasArrow>
                  <IconButton
                    aria-label="email"
                    variant="ghost"
                    size="lg"
                    fontSize="3xl"
                    icon={<MdOutlineEmail />}
                    _hover={{
                      bg: 'blue.500',
                      color: useColorModeValue('white', 'gray.700'),
                    }}
                    onClick={() => { window.location.href = 'mailto:fisiokinesislab.info@gmail.com' }}
                    isRound
                  />
                </Tooltip>

                <Box as="a" href="https://www.instagram.com/fisiokinesis_lab/" target='_blank'>
                <Tooltip
                  label={'Seguici su Instagram'}
                  closeOnClick={false}
                  hasArrow>
                  <IconButton
                    aria-label="instagram"
                    variant="ghost"
                    size="lg"
                    fontSize="3xl"
                    icon={<AiOutlineInstagram />}
                    _hover={{
                      bg: 'blue.500',
                      color: useColorModeValue('white', 'gray.700'),
                    }}
                    isRound
                  />
                  </Tooltip>
                </Box>

                <Tooltip
                  label={'Chiamaci'}
                  closeOnClick={false}
                  hasArrow>
                  <IconButton
                    aria-label="email"
                    variant="ghost"
                    size="lg"
                    fontSize="3xl"
                    icon={<AiOutlinePhone />}
                    _hover={{
                      bg: 'blue.500',
                      color: useColorModeValue('white', 'gray.700'),
                    }}
                    onClick={() => { window.location.href = 'tel:+393478888888' }}
                    isRound
                  />
                </Tooltip>

              </Stack>

              <Box
                bg={useColorModeValue('white', 'gray.700')}
                borderRadius="lg"
                p={8}
                color={useColorModeValue('gray.700', 'whiteAlpha.900')}
                shadow="base">
                <VStack spacing={5}>
                  <FormControl isRequired>
                    <FormLabel>Nome</FormLabel>

                    <InputGroup>
                      <InputLeftElement>
                        <BsPerson />
                      </InputLeftElement>
                      <Input type="text" name="name" placeholder="Il tuo nome" />
                    </InputGroup>
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel>Email</FormLabel>

                    <InputGroup>
                      <InputLeftElement>
                        <MdOutlineEmail />
                      </InputLeftElement>
                      <Input type="email" name="email" placeholder="Il tuo indirizzo Email" />
                    </InputGroup>
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel>Messaggio</FormLabel>

                    <Textarea
                      name="message"
                      placeholder="Oggetto"
                      rows={6}
                      resize="none"
                    />
                  </FormControl>

                  <Button
                    colorScheme="blue"
                    bg="blue.400"
                    color="white"
                    _hover={{
                      bg: 'blue.500',
                    }}
                    width="full"
                    onClick={handleSendMessage}
                    disabled={isButtonDisabled || serverError || messageSent} // Aggiungi serverError e messageSent alla condizione
                    isLoading={isLoading}
                  >
                    {serverError ? (
                      <span className="animate__animated animate__fadeIn">Server non raggiungibile</span>
                    ) : messageSent ? (
                      <span className="animate__animated animate__fadeIn">Messaggio Inviato!</span>
                    ) : (
                      'Invia Messaggio'
                    )}
                  </Button>
                </VStack>
              </Box>
              <Box
                bg={useColorModeValue('white', 'gray.700')}
                borderRadius="lg"
                p={8}
                color={useColorModeValue('gray.700', 'whiteAlpha.900')}
                shadow="base">
                <GoogleMapsIframe />
              </Box>
            </Stack>
          </VStack>
        </Box>
      </Box>
    </Flex>
  )
}
