import "./App.css";
import "./responsive.css";
import { Contact } from "./components/Contact.js";

import background_image from "./assets/background/background.jpeg";

import img_card_1 from "./assets/background/img_card_1.jpg";
import img_card_2 from "./assets/background/img_card_2.jpg";
import img_card_3 from "./assets/background/img_card_3.jpg";
import img_card_4 from "./assets/background/img_card_4.jpg";

import img_card_postura from "./assets/background/img_card_postura.jpg";
import brand_2 from "./assets/logo_brand.png";


import "animate.css";

//ChakraUI Imports
import {
  ChakraProvider,
  Image,
  Button,
  Text,
  Heading,
  Stack,
  SimpleGrid,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";

//import components
import { Navbar } from "./components/Navbar.js";
import { ModalStaff } from "./components/ModalStaff.js";

import { Testimonials } from "./components/Testimonials.js";

//jquery

import $ from "jquery";


function goToContact(e) {
  window.location.href = "#contact";

  e.preventDefault();
  e.stopPropagation();
}

$(function () {
 $(".item_staff").on("click", function(e) {
    $(".overlay_show_staff").removeClass("element_disabled");
    switch ($(this).data("id")) {
      default:
        break;
      case 1:
        $("#modal_1").removeClass("element_disabled");
        break;
      case 2:
        $("#modal_2").removeClass("element_disabled");
        break;
      case 3:
        $("#modal_3").removeClass("element_disabled");
        break;
      case 4:
        $("#modal_4").removeClass("element_disabled");
        break;
    }
  });

  /*$(".close_modal").click(function (e) {
    $(".overlay_show_staff").addClass("element_disabled");
    $("#modal_1").addClass("element_disabled");
    $("#modal_2").addClass("element_disabled");
    $("#modal_3").addClass("element_disabled");
    $("#modal_4").addClass("element_disabled");
  });*/
});

function App() {
  return (
    <ChakraProvider>
      <Box className="overlay_show_staff element_disabled">
        <ModalStaff />
      </Box>

      <Navbar />
      <Box className="container_introduction">
        <Image
          className="background_image"
          boxSize="full"
          src={background_image}
        />
        <Box className="containerBoxTitle">
          <Box className="containerText">
            <Box>
              <Text
                className="title_page animate__animated animate__backInRight animate__slower"
                fontSize="100px"
              >
                <strong className="title_strong">FISIO</strong>KINESIS
              </Text>
              <Text className="coso_sotto" fontSize="40px">
                LAB
              </Text>
              <Text className="coso_lungo_sotto" fontSize="40px">
                Breath Movement and training
              </Text>
            </Box>
          </Box>
          <Box className="container_show_more">
            <Button
              className="button_show_more"
              size="sm"
              onClick={goToContact}
            >
              <Text className="text_button_welcomebanner" fontSize="md">
                Scopri di più
              </Text>
            </Button>
          </Box>
        </Box>
      </Box>

      <Box
        className="content_page"
        id="activities"
        backgroundColor={useColorModeValue("gray.100", "gray.900")}
      >
        <Box
          className="container_banner"
          w="100%"
          h="200px"
          bgGradient="linear(to-l, #ee751c, #d55858)"
        >
          <Text fontSize="40px" className="anim-typewriter" color={"white"}>
            Esplora il sito e scopri i
            <strong className="badge_custom_strong">nostri percorsi</strong>
          </Text>
        </Box>
        <Box
          className="container_info_gallery animate__animated animate__fadeInUp animate__slower"
          style={{ paddingBottom: "70px" }}
          backgroundColor={useColorModeValue("gray.100", "gray.900")}
        >
          <SimpleGrid className="simple_grid" columns={2} spacing={0}>
            <Box className="content_grid_item">
              <Image
                className="image_grid"
                boxSize="full"
                src={img_card_1}
                alt="Dan Abramov"
              />
            </Box>
            <Box className="content_grid_item">
              <Box className="container_info_item">
                <Text fontSize="40px" className="title_info_grid">
                  Percorso Benessere
                </Text>
                <Text fontSize="xl">
                  Il percorso benessere è un percorso per chi vuole raggiungere
                  l’eccellenza della propria condizione psico-fisica.
                  Struttureremo il mind-set necessario al raggiungimento degli
                  obiettivi, metodiche antiaging e salutogeniche per ottimizzare
                  il viaggio annuale che affronteremo.
                </Text>
                <Box className="container_show_more show_more_grid_option">
                  <Button
                    className="button_show_more"
                    colorScheme="teal"
                    size="sm"
                    onClick={goToContact}
                  >
                    <Text fontSize="md">Scopri di più</Text>
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box className="content_grid_item">
              <Box className="container_info_item">
                <Text fontSize="40px" className="title_info_grid">
                  Percorso Donna
                </Text>
                <Text fontSize="xl">
                  Il percorso donna è ideale per chi tende a demotivarsi
                  facilmente, vuole perdere peso oppure sentirsi meglio con il
                  proprio corpo. Motivazione e fiducia in sé stessa sono le basi
                  sulle quali costruiremo i tuoi obiettivi. Se hai una patologia
                  insieme al tuo medico ti aiuteremo a gestirla al meglio.
                </Text>
                <Box className="container_show_more show_more_grid_option">
                  <Button
                    className="button_show_more"
                    colorScheme="teal"
                    size="sm"
                    onClick={goToContact}
                  >
                    <Text fontSize="md">Scopri di più</Text>
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box className="content_grid_item">
              <Image
                className="image_grid"
                boxSize="full"
                src={img_card_2}
                alt="Dan Abramov"
              />
            </Box>
            <Box className="content_grid_item">
              <Image
                className="image_grid"
                boxSize="full"
                src={img_card_3}
                alt="Dan Abramov"
              />
            </Box>
            <Box className="content_grid_item">
              <Box className="container_info_item">
                <Text fontSize="40px" className="title_info_grid">
                  Percorso Performance
                </Text>
                <Text fontSize="xl">
                  Il percorso performance è pensato per atleti professionisti
                  (e/o amatoriali) che vogliono migliorare la propria
                  performance con allenamenti individualizzati e protocolli per
                  il recupero psico-fisico raggiungendo l’eccellenza.
                </Text>
                <Box className="container_show_more show_more_grid_option">
                  <Button
                    className="button_show_more"
                    colorScheme="teal"
                    size="sm"
                    onClick={goToContact}
                  >
                    <Text fontSize="md">Scopri di più</Text>
                  </Button>
                </Box>
              </Box>
            </Box>

            <Box className="content_grid_item">
              <Box className="container_info_item">
                <Text fontSize="40px" className="title_info_grid">
                  Percorso Postura
                </Text>
                <Text fontSize="xl">
                  Il programma postura è pensato per giovani e adolescenti che
                  necessitano di stimolare il proprio sistema neuro-muscolare,
                  raggiungere l’equilibrio più efficiente e funzionale. La
                  postura è innanzitutto espressione dei moti interiori di
                  ognuno, ecco perché parleremo della connessione mente-corpo
                  con genitori e ragazzi.
                </Text>
                <Box className="container_show_more show_more_grid_option">
                  <Button
                    className="button_show_more"
                    colorScheme="teal"
                    size="sm"
                    onClick={goToContact}
                  >
                    <Text fontSize="md">Scopri di più</Text>
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box className="content_grid_item">
              <Image
                className="image_grid"
                boxSize="full"
                src={img_card_postura}
                alt="Dan Abramov"
              />
            </Box>
            <Box className="content_grid_item">
              <Image
                className="image_grid"
                boxSize="full"
                src={img_card_4}
                alt="Dan Abramov"
              />
            </Box>
            <Box className="content_grid_item">
              <Box className="container_info_item">
                <Text fontSize="40px" className="title_info_grid">
                  Percorso Rehab
                </Text>
                <Text fontSize="xl">
                  Il percorso rehab è pensato per chi è affetto da una patologia
                  stabilizzata oppure ha subito un intervento e vuole recuperare
                  la funzionalità corporea. Per lo sportivo che ha subito un
                  infortunio oppure un intervento chirurgico.
                </Text>
                <Box className="container_show_more show_more_grid_option">
                  <Button
                    className="button_show_more"
                    colorScheme="teal"
                    size="sm"
                    onClick={goToContact}
                  >
                    <Text fontSize="md">Scopri di più</Text>
                  </Button>
                </Box>
              </Box>
            </Box>
          </SimpleGrid>
        </Box>
        <Box
          bg={useColorModeValue("gray.200", "gray.700")}
          className="dividerOblique"
        />


        <Box className="container_feedback" id="feedbacks">
          <Testimonials />
        </Box>


        <Box id="about" className="container_staff">
          <Box className="item_staff" data-id="1">
            <Box className="image_profile"></Box>
            <Box className="profile_details">
              <p className="p_name_staff">Vittorio Carcatella</p>
              <Box className="divider"></Box>
              <p className="p_desc_staff">
                Descrizione breve
              </p>
              <Box className="container_show_more">
                <Button
                  className="button_scopri"
                  size="sm"
                  onClick={goToContact}
                >
                  <Text fontSize="md">
                    Scopri di più
                  </Text>
                </Button>
              </Box>
            </Box>
          </Box>

          <Stack spacing={5} align={"center"}>
            <Heading textColor={"black"}>Lo Staff di Fisiokinesis Lab</Heading>
            <Text textColor={"black"} style={{ wordWrap: 'break-word', width: '300px', textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }}>
              Il nostro staff è composto da professionisti qualificati e
              specializzati sotto ogni aspetto.

              Ognuno di loro è in grado di
              fornire un servizio di qualità e personalizzato per ogni
              esigenza fisica.
            </Text>
          </Stack>




          <Box className="item_staff" data-id="1">
            <Box className="image_profile"></Box>
            <Box className="profile_details">
              <p className="p_name_staff">Vittorio Carcatella</p>
              <Box className="divider"></Box>
              <p className="p_desc_staff">
                Descrizione breve
              </p>
              <Box className="container_show_more">
                <Button
                  className="button_scopri"
                  size="sm"
                  onClick={goToContact}
                >
                  <Text fontSize="md">
                    Scopri di più
                  </Text>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        
        
        <Contact />

        <Box
          className="container_banner"
          id="footer"
          backgroundColor={useColorModeValue("gray.100", "gray.900")}
        >
          <img
            src={brand_2}
            alt=""
            style={{ position: "sticky", paddingRight: "7cm" }}
          />
          <Text style={{ fontWeight: "bold" }}>
            Copyright © 2023 Fisiokinesis Lab. Tutti i diritti riservati
          </Text>
        </Box>
      </Box>

      <Box className="container_wt_float">
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <a
            href="https://wa.me/393929000000"
            className="whatsapp_float"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="whatsapp_float img"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/WhatsApp_icon.png/598px-WhatsApp_icon.png"
              alt="whatsapp"
            />

            <Box
              style={{
                backgroundColor: 'green',
                color: 'white',
                padding: '10px',
                borderRadius: '5px',
                marginLeft: '10px'
              }}
            >
              <p style={{ fontSize: '20px', fontWeight: 'bold' }}>Chatta con noi!</p>
            </Box>
          </a>
        </Box>
      </Box>


    </ChakraProvider>
  );
}

export default App;
