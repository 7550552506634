import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";

import {
  Box,
  Flex,
  HStack,
  Link,
  IconButton,
  useDisclosure,
  useColorModeValue,
  Stack,
} from "@chakra-ui/react";
import brand from "../assets/logo.png";

export function Navbar() {
  const Links = ["Home", "Attività", "Chi Siamo", "Recensioni", "Contattaci"];
  const sections = ["", "activities", "about", "feedbacks", "contact"]

  const NavLink = ({ children }) => (
    <Link
      px={6}
      py={1}
      rounded={"md"}
      _hover={{
        textDecoration: "none",
        bg: useColorModeValue("gray.200", "gray.700"),
      }}
      href={"#" + sections[Links.indexOf(children)]}
    >
      {children}
    </Link>
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      <Flex
        h={16}
        className="navbar"
        justifyContent={"left"}
      >
        <IconButton
          className="hamburger-button"
          size={"md"}
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label={"Open Menu"}
          display={{ md: "none" }}
          onClick={isOpen ? onClose : onOpen}
        />
        <HStack spacing={8} alignItems={"center"}>
          <img className="logo_navbar" src={brand} alt="" />
          <HStack
            className="menu-items"
            as={"nav"}
            spacing={10}
            display={{ base: "none", md: "flex" }}
          >
            {Links.map((link) => (
              <NavLink key={link}>{link}</NavLink>
            ))}
          </HStack>
        </HStack>
        <Flex alignItems={"center"}>
        </Flex>
      </Flex>

      {isOpen ? (
        <Box className="nav_smartphone" pb={4} display={{ md: "none" }}>
          <Stack as={"nav"} spacing={4}>
            {Links.map((link) => (
              <NavLink key={link}>{link}</NavLink>
            ))}
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
}

