import React from 'react';

export function GoogleMapsIframe() {
  return (
    <div className='container_maps'>
      <iframe
        title="Colosseo Street View"
        width="600"
        height="450"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3018.66423350542!2d14.346193377296055!3d40.83533823005376!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x133ba7521cf3cac7%3A0x30c8377963921cc!2sFisiokinesis%20Lab!5e0!3m2!1sit!2sit!4v1691018905173!5m2!1sit!2sit"
        allowFullScreen
      ></iframe>
    </div>
  );
};
